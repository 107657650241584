.menu-item {
  width: 90%;
  height: 2.8rem;
  margin-bottom: 1px;
  color: #000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  > * {
    fill: #fff;
  }

  &__image {
    display: block;
    width: 1rem;
  }
  &__element-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    width: 80%;
    height: 2.5625rem;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    margin-bottom: 0.25rem;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 2rem 1rem;
  box-sizing: border-box;
  margin-top: auto;
  &__item {
    width: 94%;
    height: 3rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    color: #000;
    background: #ffffff;
    margin-bottom: 0.3rem;
  }
  &__image {
    width: 1.4rem;
    margin-right: 1rem;
    * {
      fill: #be00ed;
    }
  }
  &__title {
    background-color: #780097;
    width: 100%;
    height: 2.9rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 1.5rem;
    box-sizing: border-box;
    font-weight: bold;
    margin-bottom: 0.7rem;
  }
}
